import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'
import '../components/all.sass'

export default function MarketingRequirementsTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath}>
      <h2 className="title is-2">Project: {frontmatter.projectName}</h2>
      <section className="content">
        <ol type="1">
          <li className="title is-4 has-text-info">
            <b>Project Definition</b>
          </li>
          <p>
            <i>
              The purpose of this project is to {frontmatter.projectPurpose}
            </i>
          </p>
          <li className="title is-4 has-text-info">
            <b>Stakeholders</b>
          </li>
          <p>
            <i>
              This section outlines the involvement and impact of any outside
              stakeholders in the project.
            </i>
          </p>
          <p className="ml-5">
            <h4 className="title is-5 has-text-info">Advisors</h4>
            <p>
              <i>
                Enter the names and impact of any advisors involved in this
                project here.
              </i>
            </p>
            <Table table={frontmatter.advisorsTable} />
            <h4 className="title is-5 has-text-info">Decision Makers</h4>
            <p>
              <i>Enter the names and impact of any decision makers here.</i>
            </p>
            <Table table={frontmatter.decisionMakersTable} />
            <h4 className="title is-5 has-text-info">Approvers</h4>
            <p>
              <i>Enter the names and impact of any approvers here.</i>
            </p>
            <Table table={frontmatter.approversTable} />
          </p>
          <li className="title is-4 has-text-info">
            <b>Context</b>
          </li>
          <p>
            <i>
              This section outlines the Big Picture view of everything
              surrounding the project.
            </i>
          </p>
          <p className="ml-5">
            <h4 className="title is-5 has-text-info">
              Need Description (Why?)
            </h4>
            <p>
              <i>
                This section describes the pressing needs that are driving this
                design project.
              </i>
            </p>
            <Table table={frontmatter.needDescriptionTable} />
            <h4 className="title is-5 has-text-info">
              Current Solutions Description (How?)
            </h4>
            <p>
              <i>
                This section describes the current attempts (our competition) to
                meet the pressing needs and their insufficiencies.
              </i>
            </p>
            <Table table={frontmatter.currentSolutionsDescriptionTable} />
            <h4 className="title is-5 has-text-info">
              User Description (Who?)
            </h4>
            <p>
              <i>
                This section describes the people who will be affected by the
                use of this product.
              </i>
            </p>
            <Table table={frontmatter.userDescriptionTable} />
            <h4 className="title is-5 has-text-info">
              Functional Description (What?)
            </h4>
            <p>
              <i>
                This section describes each function that the design is expected
                to perform.
              </i>
            </p>
            <Table table={frontmatter.functionalDescriptionTable} />
            <h4 className="title is-5 has-text-info">
              Environment Description (Where?)
            </h4>
            <p>
              <i>
                This section describes the environment in which the design will
                be used, such as location, temperature, humidity, and any
                concurrent activities.
              </i>
            </p>
            <Table table={frontmatter.environmentalDescriptionTable} />
            <h4 className="title is-5 has-text-info">
              Situation Description (When?)
            </h4>
            <p>
              <i>
                This section describes when the device will be used and accounts
                for such periods as transportation and storage.
              </i>
            </p>
            <Table table={frontmatter.situationDescriptionTable} />
          </p>
          <li className="title is-4 has-text-info">
            <b>Usage Scenarios</b>
          </li>
          <p>
            <i>
              This section outlines the anticipated usage scenarios for the
              design.
            </i>
          </p>
          <Table table={frontmatter.usageScenariosTable} />
          <li className="title is-4 has-text-info">
            <b>Requirements</b>
          </li>
          <p>
            <i>
              This section outlines the marketing requirements for the project
              as determined with input from internal sources and outside
              stakeholders. This is NOT a comprehensive list of specifications.
              Rather, please describe what this design must DO. Qualitative
              descriptors are acceptable here as long as they are well-defined.
            </i>
          </p>
          <Table table={frontmatter.requirementsTable} />
          <li className="title is-4 has-text-info">
            <b>Time Constraints</b>
          </li>
          <p>
            <i>
              This section outlines anticipated deadlines such as tradeshows and
              testing dates as well as the associated deliverables.
            </i>
          </p>
          <Table table={frontmatter.timeConstraintsTable} />
          <li className="title is-4 has-text-info">
            <b>Projections</b>
          </li>
          <p>
            <i>
              This section outlines the expected annual sales and anticipated
              sales price for components of the new design for the purposes of
              cost analysis and quoting.
            </i>
          </p>
          <Table table={frontmatter.projectionsTable} />
        </ol>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryMarketingRequirements($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        projectName
        projectPurpose
        advisorsTable {
          advisor
          projectImpact
        }
        decisionMakersTable {
          decisionMakers
          projectImpact
        }
        approversTable {
          approvers
          projectImpact
        }
        needDescriptionTable {
          need
          description
        }
        currentSolutionsDescriptionTable {
          solution
          description
          shortcomings
        }
        userDescriptionTable {
          user
          description
        }
        functionalDescriptionTable {
          function
          description
        }
        environmentalDescriptionTable {
          environment
          description
        }
        situationDescriptionTable {
          situation
          description
        }
        usageScenariosTable {
          scenario
          description
        }
        requirementsTable {
          category
          requirement
        }
        timeConstraintsTable {
          date
          deliverables
        }
        projectionsTable {
          region
          item
          estimatedAnnualQuantitiesForYearOne
          estimatedAnnualQuantitiesForYearTwo
          estimatedAnnualQuantitiesForYearThree
        }
      }
    }
  }
`
